<template>
  <div class="mt-0" align="center">

  </div>
</template>
<script>
import axios from 'axios'
import { ecomService_dotnet } from '@/website/global'
import { DateNow, FormatInvoice, formatMoney } from '@/website/global_function'
import { header_token } from '@/website/backend/token'
  export default {
    components: {
      // 'barcode': VueBarcode,
      // VueQrcode
    },
    data: () => ({

      shop_id: null,
      url_callback: null
    }),
    async created () {
      this.shop_id = localStorage.getItem('shop_id')

      this.lazada_code = this.$route.query.code
      if(this.$route.query.state!=null){
        var path = this.$route.query.state.toString();
        //console.log(path);
        if(path!=""){
          if(path.indexOf("?")>0){
            path=path+"&code="+ this.$route.query.code
          }else{
            path=path+"?code="+ this.$route.query.code
          }
          window.location.assign(path);
        }
      }
      this.lazada_code = this.$route.query.state
      this.fullPath = window.location.href

      if (typeof(this.lazada_code) ==='undefined') {
        this.lazada_code = null
      }

      if(this.shop_id != null){
        this.update_data_call_back()
      }else {
        // encode fullphat
        var url_encode = encodeURIComponent(this.url_callback)
        this.$router.push('/login?ref='+ url_encode)
      }
    },
    watch: {
    },
    methods: {

      async update_data_call_back() {
        // console.log('start');
        // console.log(shopCallbackService+'shopee/callback');
        this.loading = true

        var bool_check_init_lazada = localStorage.getItem('check_init_lazada')
        if(bool_check_init_lazada == 'false'){
          bool_check_init_lazada = false
        } else if (bool_check_init_lazada == 'true') {
          bool_check_init_lazada = true
        }

        var lazada_connect_warehouse = localStorage.getItem('lazada_connect_warehouse')
        var lazada_init_timerange = JSON.parse(localStorage.getItem('lazada_init_timerange'))
        let response1 = await axios.post(ecomService_dotnet+'Lazada/callback',{
          "shopID": this.shop_id ,
          "isInit": bool_check_init_lazada,
          "warehouseID":lazada_connect_warehouse,
          "callbackUrl": this.fullPath,
          "initTimeRanges":lazada_init_timerange
        } , { headers: header_token})

        this.loading = false
        localStorage.removeItem("check_init_lazada");
        localStorage.removeItem("lazada_connect_warehouse");
        this.$router.push('/connect')

        // let response = await axios.post(shopCallbackService+'lazada/callback', {
        //   'shop_id': this.shop_id,
        //   'code' : this.lazada_code
        // })
        // if (response.data.status == 'Success') {
        //     if(response.data.massage == 'success'){
        //       // this.update_data_connect()
        //       this.$router.push('/connect')
        //     }
        // }
      },

      // async update_data_connect () {
      //   let response = await axios.post(shopCallbackService+'lazada/update_data_monthlyfee', {
      //     'shop_id': localStorage.getItem('shop_id'),
      //     'staff_id' : localStorage.getItem('staff_id'),
      //     'branch_id' : localStorage.getItem('branch_id')
      //   })
      //   if (response.data.status == 'Success') {
      //     // console.log('end');
      //     console.log('update monthlyfee success');
      //   }
      //   this.loading = false
      // },
    },
  }
</script>
