<template>
    <v-row >
        <v-col v-if="Window == 'pc'" class="bg-loginOasys resizeBg fill-height"> 
          <!-- ไปแก้css login ตรงที่ทำ -->
        </v-col>
      <v-col   cols="12" md="6" lg="4" align="center" class="layout">
        <v-card height="100%" width="100%">
            <br><br>
        <v-card-title v-if="nextstep ===2 || nextstep ===3">
            <a @click="handleStepClick(1)"><p class="fontcss" style="color: #47a2a2;" >ย้อนกลับ</p></a>
        </v-card-title>
          <v-card-text v-if="nextstep === 2">
            <br><br><br><br>
            <v-row>
              <v-col class="pt-5" cols="12" md="5" align="center">
                <h1 class="fontcss" style="color: #000;">ลืมรหัสผ่าน</h1>
              </v-col>
              <v-col class="pt-5" cols="12" align="left">
                 <label style="font-size: 15px; padding: 20px;">รีเซ็ตรหัสผ่านด้วย email ที่ลงทะเบียนกับ Oasys</label>
              </v-col>
            </v-row >
            <v-form ref="formEmail" class="pt-2" v-model="valid" lazy-validation >
              <v-row justify="center">
                <v-col cols="10"  class="pb-0" >
                  <v-text-field
                    autofocus
                    v-model="email"
                    :rules="[(v) => !!v || 'กรุณากรอกอีเมล',v => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง']"
                    required
                    ref="refemail"
                    label="Email"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col class="pb-2" cols="10">
                    <v-btn
                    class="btn-login rounded-lg fontcss"
                    :loading="loading"
                    @click="handleSendMail()">ยืนยัน</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-else-if="nextstep === 3">
            <br><br><br><br>
            <v-row>
              <v-col class="pt-5" cols="12" md="5" align="center">
                <h1 class="fontcss" style="color: #000;">ลืมรหัสผ่าน</h1>
              </v-col>
              <v-col class="pt-5" cols="12" align="left">
                 <label style="font-size: 15px; padding: 20px;">รีเซ็ตรหัสผ่านด้วยเบอร์โทรศัพท์ที่ลงทะเบียนกับ Oasys</label>
              </v-col>
            </v-row >
            <v-form ref="formPhone" class="pt-2" v-model="valid" lazy-validation >
              <v-row justify="center">
                <v-col cols="8" class="pb-0" >
                  <v-text-field
                    v-model="phone"
                    :rules="[(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์']"
                    required
                    ref="refphone"
                    label="เบอร์โทร"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                    maxlength="10"
                    dense hide-details
                  ></v-text-field>
                  <span v-if="ShowSendOtp" style="color: green; display: flex;">ส่งOTPเรียบร้อยแล้ว</span>
                  <span v-if="ShowErrorOtp" style="color: red; display: flex;">{{ otptextError }}</span>
                </v-col>
                <v-col cols="2" class="pb-0" >
                  <v-btn
                    id="countdownButton"
                    class="fontcss"
                    style="background-color: #23baaa; color: #ffff;"
                    :disabled="isCountingDown"
                    @click="handleSendOtp()">{{OTPtext}}</v-btn>
                </v-col>

                <v-col cols="10" class="pb-0" >
                  <v-text-field
                    v-model="otp"
                    :rules="[(v) => !!v || 'กรุณากรอกotp']"
                    required
                    ref="refotp"
                    label="OTP"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                    maxlength="6"
                    dense hide-details
                  ></v-text-field>
                  <span v-if="ShowErrorOtp2" style="color: red; display: flex;">กรุณากรอกotp</span>
                </v-col>
                <v-col class="pb-2" cols="10">
                    <v-btn
                    class="btn-login rounded-lg fontcss"
                    :loading = "loading"
                    @click="handleCheckOtp()">ยืนยัน</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-else-if="nextstep === 1">
            <br><br><br><br><br><br><br>
            <v-row>
              <v-col class="pt-5" cols="12" md="5" align="center">
                <h1 class="fontcss" style="color: #000;">ลืมรหัสผ่าน</h1>
              </v-col>
              <v-col class="pt-5" cols="12" align="center">
                 <label style="font-size: 15px; padding: 13px;">เลือกวิธีรีเซ็ตรหัสผ่านด้วยอีเมลหรือเบอร์โทรศัพท์ที่ลงทะเบียน กับ Oasys</label>
              </v-col>
              <v-col class="pt-5" cols="12" align="center">
                <v-btn
                    class="btn-register rounded-lg fontcss"
                    @click="handleStepClick(2)">อีเมล</v-btn>
              </v-col>
              <v-col class="pt-5" cols="12" align="center">
                <v-btn
                    class="btn-register rounded-lg fontcss"
                    @click="handleStepClick(3)">เบอร์โทรศัพท์</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else-if="nextstep === 0">
            <!-- nextstep === 3 -->
            <br><br><br><br><br><br><br>
            <v-row>
                <v-col class="pt-5" cols="12" md="5" align="center">
                <h1 class="fontcss" style="color: #000;">ลืมรหัสผ่าน</h1>
              </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <img src="@/assets/illustration_send_email.png" width="120px" />
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <label class="body-1">ระบบได้ทำการส่งอีเมลสำหรับรีเซ็ตพาสเวิร์ดไปยัง <label style="color: rgb(118,208,200);">{{ email }} </label> เรียบร้อย </label> <br>
               <label class="body-1">กรุณาคลิกลิ้งค์ในอีเมลเพื่อดำเนินการในขั้นตอนถัดไป</label>
            </v-col>

          </v-row >
          </v-card-text>
            <v-col class="pt-5" cols="12" md="8" align="center">
            <v-divider :thickness="6" class="border-opacity-100"></v-divider><br>
            <label class="body-1">มีบัญชีร้านค้ากับเราแล้ว ?</label> <a class="subtitle-1" style="color: rgb(118,208,200);" @click="navigate()">ล็อคอินเข้าระบบ</a>
              </v-col>
        </v-card>
      </v-col>
    </v-row>
  
  </template>
  <style  scoped >
  
  .btn-login{
  font-size: 2em !important;
  width:50% !important;
  height:60px !important;
  color: #ffffff !important;
  background: #23baaa !important;
  }
  .text-center {
  text-align: center; /* ใช้ text-align: center เพื่อจัดข้อความในตำแหน่งกลาง */
  }
  @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
  
  .fontcss {
    font-family: 'Prompt', sans-serif;
  }
  .layout {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }
  .btn-register{
  font-weight: bolder !important; 
  font-size: 1.3em !important;
  width:70% !important;
  height:50px !important;
  color: black !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
  
  }
  </style>
  <script>
  import axios from "axios";
  import Swal from 'sweetalert2'
  import { token_api } from "@/website/token";
  import { isNumberWNoDot } from '@/website/global_function'
  import {  shopService_dotnet } from "@/website/global";
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
  export default {
  data: () => ({
  valid: true,
  loading: false,
  username: "",
  phone: "",
  otp:"",
  ShowSendOtp:false,
  ShowErrorOtp:false,
  ShowErrorOtp2:false,
  otptextError:"",

  email:"",
  password:"",
  confirmPassword:"",
  show_password: false,
  show_confirmPassword: false,
  nextstep:1,
  countdownTime: 0,
  isCountingDown: false,
  intervalId: null,
  
      // size จอ
      size: "lg",
      window: {
        width: 0,
        height: 0,
      },
  
      // ขนาดจอ
      Window: "pc",
  }),
  computed: {
    OTPtext() {
      return this.isCountingDown ? `${this.countdownTime}` : 'OTP';
    },
  },
  async created() {
  
  window.addEventListener("resize", this.handleResize);
  this.handleResize();
  },
  watch: {
      size() {
        this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  methods: {
  async handleStepClick(value) {
    this.nextstep = value
    if(value === 1){
        this.email = ""
        this.phone = ""
        this.otp = ""
        this.ShowSendOtp  = false
        this.ShowErrorOtp  = false
        this.ShowErrorOtp2  = false
    }
  },
  async handleSendMail(){
    if (this.$refs.formEmail.validate()) {
    this.loading = true
    let response = await axios.post(
        shopService_dotnet+"Shop/reset-password-by-email-send-email",
      {
        email: this.email,
      },
      {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }}
    );
    if(response.data.status === 'success')
    {
        this.loading = false
        this.handleStepClick(0)
    }
    else{
    this.loading = false
    Swal.fire({
      toast: true,
      timer: 3000,
      position: 'top',
      type: 'error',
      title: response.data.message.th, //hardcode ภาษาไทยไปก่อน
      showConfirmButton: false,
    })
    }
  }
  },
  async handleCheckOtp(){
    this.ShowSendOtp  = false
    this.ShowErrorOtp  = false
    this.ShowErrorOtp2  = false
    if(this.phone === "" && this.otp === "")
    {
      this.ShowErrorOtp  = true
      this.otptextError = 'กรุณาระบุเบอร์โทรศัพท์'
      this.ShowErrorOtp2  = true
      this.$refs.refphone.focus()
      return 
    }
    if(this.phone === "")
    {
      this.ShowErrorOtp  = true
      this.otptextError = 'กรุณาระบุเบอร์โทรศัพท์'
      this.$refs.refphone.focus()
      return 
    }
    if(this.otp === "")
    {
      this.ShowErrorOtp2  = true
      this.$refs.refotp.focus()
      return 
    }
    if (this.$refs.formPhone.validate()) {
    this.loading = true
    let response = await axios.post(
        shopService_dotnet+"Shop/reset-password-by-mobile-check-otp",
      {
        phone: this.phone,
        otp: this.otp,
      },
      {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }}
    );
    if(response.data.status){
        this.loading = false
        localStorage.setItem('phone_number', this.phone)
        this.$router.push("/auth/reset-password");
    }
    else{
        this.loading = false
        Swal.fire({
            toast: true,
            timer: 3000,
            position: 'top',
            type: 'error',
            title: response.data.message,
            showConfirmButton: false,
            })
    }
  }
  },
  async handleSendOtp(){
    this.ShowErrorOtp  = false
    this.ShowSendOtp  = false
    this.ShowErrorOtp2  = false
    this.otptextError = ""
    if(this.phone === "")
    {
      this.ShowErrorOtp  = true
      this.otptextError = 'กรุณาระบุเบอร์โทรศัพท์'
      this.$refs.refphone.focus()
      return 
    }
    let response = await axios.post(
        shopService_dotnet+"Shop/reset-password-by-mobile-send-otp",
      {
        phone: this.phone,
      },
      {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }}
    );
    if(response.data.status)
    {
    //   await AlertSuccess('ส่งOTPเรียบร้อยแล้ว')
    this.ShowSendOtp  = true
    this.ShowErrorOtp  = false
    this.ShowErrorOtp2  = false
    this.startCountdown()
    }
    else{
        this.ShowErrorOtp  = true
        this.ShowSendOtp  = false
        this.otptextError = response.data.message
    }
  },
  startCountdown() {
      if (this.isCountingDown) return; // ป้องกันการเริ่มนับถอยหลังซ้ำถ้ามันกำลังนับอยู่
      this.isCountingDown = true;
      this.countdownTime = 60;
      this.updateCountdown();  // อัปเดตก่อน setInterval
      this.intervalId = setInterval(() => {
        if (this.countdownTime > 0) {
          this.updateCountdown();
        } else {
          this.resetCountdown();
        }
      }, 1000); 
    },
  updateCountdown() {
      this.countdownTime -= 1;
    },
  resetCountdown() {
      clearInterval(this.intervalId);
      this.isCountingDown = false;
      this.countdownTime = 60;
    },
  
  navigate()  {
    this.$router.push("/loginOasys");
  },
  handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        if (this.window.width < 600) {
          this.size = "xs";
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = "sm";
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = "md";
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = "lg";
        } else if (this.window.width >= 1904) {
          this.size = "xl";
        }
      },
  isNumberWNoDot,
  AlertSuccess,
  AlertWarning,
  AlertError,
  },
  };
  </script>
  <style scoped>
 
  @media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
      .resizeBg{
        height: 100vh !important;
      }
       
    }
    @media screen and (min-device-width: 1500px)   {
      .resizeBg{
        height: 180vh !important;
      }
       
    }
  
  </style>