<template>
  <div class="mt-0" align="center">

  </div>
</template>
<script>
import axios from 'axios'
import { ecomService_dotnet } from '@/website/global'
import { DateNow, FormatInvoice, formatMoney } from '@/website/global_function'
import { header_token } from '@/website/backend/token'
import Swal from 'sweetalert2'
  export default {
    components: {
      // 'barcode': VueBarcode,
      // VueQrcode
    },
    data: () => ({

      shop_id: null,
      url_callback: null
    }),
    async created () {

      this.shop_id = localStorage.getItem('shop_id')
      this.url_callback = window.location.href

      this.tiktok_code = this.$route.query.code
      if(this.$route.query.state!=null){
         var path = this.$route.query.state.toString();
         if(path!=""){
          if(path.indexOf("?")>0){
            path=path+"&code="+ this.$route.query.code
          }else{
            path=path+"?code="+ this.$route.query.code
          }
          window.location.assign(path);
        }
      }
      if(this.shop_id != null){
        await this.update_data_call_back()
      }else {
        // encode full path
        var url_encode = encodeURIComponent(this.url_callback)
        this.$router.push('/login?ref='+ url_encode)
      }
    },
    watch: {
    },
    methods: {
      // DateNow,
      async update_data_call_back() {
        var check_init_tiktok = localStorage.getItem('check_init_tiktok')
        var warehouseid = localStorage.getItem('tiktok_connect_warehouse')
        var tiktok_init_timerange = JSON.parse(localStorage.getItem('tiktok_init_timerange'))
        try {
          this.loading = true
          let response = await axios.post(ecomService_dotnet+'Tiktok/callback',{
            "code": this.tiktok_code,
            "shopID": this.shop_id,
            "isInit": check_init_tiktok,
            "warehouseID":warehouseid,
            "initTimeRanges":tiktok_init_timerange
          } , { headers: header_token})
          localStorage.removeItem("check_init_tiktok");
          localStorage.removeItem("tiktok_connect_warehouse");
          this.loading = false
          this.$router.push('/connect')
        } catch (e) {
          this.$swal({
            type: 'warning',
            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
          })
          this.$router.push('/connect')
        }
      },

    },
  }
</script>
