<template>
  <div style="max-height : 600px; padding-top: 300px;" class="px-5" align="center">
    <span style="color: red">Connection in progress</span><br><br>
    <v-progress-circular
      indeterminate
      color="red darken-2"
    ></v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";
import { expressService_dotnet } from "@/website/global";
export default {
  name: 'OasysShopFacebookPage',

  data() {
    return {
      page_loading: true,
      header_token: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},

      // fb
      fbSignInParams: {
        scope: 'public_profile, email, pages_show_list, pages_manage_metadata, pages_messaging, pages_manage_engagement, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_cta, page_events, pages_messaging_subscriptions',
        return_scopes: true
      },
      codeFacebook: '',
      curDomain: null
    };
  },

  async created() {
    this.curDomain = window.location.origin;

    await this.facebooksignup()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.codeFacebook = urlParams.get('code')
    if (this.codeFacebook != null) {
      this.saveFacebookPage()
      // console.log('codeFacebook: ', this.codeFacebook);
    }
  },

  methods: {
    async facebooksignup(){
      window.fbAsyncInit = function() {
      this.FB.init({
        appId      : '849982039770741',
        cookie     : true,
        xfbml      : true,
        version    : 'v17.0'
      });
      };
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },

    async saveFacebookPage() {
      var appID = '849982039770741'
      var appSecret = '79e51729474c1684a2890363a327dacc'
      // var url = 'https://localhost:8082/facebook/callback'
      var url = this.curDomain + '/facebook/callback'
      var code = this.codeFacebook
      var longToken = ''

      // console.log('codeFacebook: ', code);
      fetch(`https://graph.facebook.com/v17.0/oauth/access_token?client_id=${appID}&redirect_uri=${url}&client_secret=${appSecret}&code=${code}`)
      .then(response => response.json())
      .then(data => {
        const shortLivedToken = data.access_token;

        fetch(`https://graph.facebook.com/v17.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${appID}&client_secret=${appSecret}&fb_exchange_token=${shortLivedToken}`)
          .then(response => response.json())
          .then(longTokenData => {
            longToken = longTokenData.access_token

            window.FB.api('/me', 'GET',
              { "fields": 'id,name,email,picture', "access_token": longToken},
              async resUser => {
                // console.log('resUser: ', resUser);
                // console.log('Long-lived token:', longToken);

                window.FB.api('/me/accounts?fields=name,id,access_token,picture', 'GET',
                  {"access_token": longToken},
                  async resPage => {
                    // console.log('resPage: ', resPage);

                    var list = []
                    resPage.data.forEach((e) => {

                      window.FB.api( "/" + e.id + "/subscribed_apps?subscribed_fields=feed&access_token=" + e.access_token, "POST",
                        { "subscribed_fields": "name, picture, messages, message_deliveries, message_reads" },
                        async resSub => {
                          // console.log('resSub: ', resSub);
                        }
                      );

                      list.push(
                        {
                          "pageId": e.id,
                          "pageName": e.name,
                          "pagePictureProfile": e.picture.data.url,
                          "pageAccessToken": e.access_token,
                          "tokenExpire": null,
                          "isDelete": false
                        }
                      )
                    })
                    // console.log('list page: ', list);

                    if (list.length > 0) {
                      let response = await axios.post(
                        expressService_dotnet + "CoreMessage/connect-page-facebook",
                        {
                          "shopID": parseInt(localStorage.getItem("shop_id")),
                          "facebookId": resUser.id,
                          "facebookName": resUser.name,
                          "facebookPictureProfile": resUser.picture.data.url,
                          "userAccessToken": longToken,
                          "tokenExpire": null,
                          "pages": list
                        },
                        { headers: this.header_token }
                      );
                      if (response.data.status == 'success') {
                        // window.location.href = 'https://localhost:8082/connect'
                        window.location.href = this.curDomain + '/connect'
                      }
                    }
                  }
                )
              }
            )
          });
        }
      );
    },
  },
};
</script>