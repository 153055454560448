<template>
  <v-row>
    <v-col v-if="Window == 'pc'" class="bg-loginOasys resizeBg fill-height">
      <!-- ไปแก้css login ตรงที่ทำ -->
    </v-col>
    <v-col cols="12" md="6" lg="4" align="center" class="layout">
      <v-card height="100%" width="100%">
        <v-card-text v-if="!nextstep">
          <v-row>
            <v-col class="pt-5" cols="12" align="left">
              <h1 class="fontcss text-center" style="color: #424242">
                สมัครสมาชิก
              </h1>
            </v-col>
          </v-row>
          <v-form ref="form" class="pt-5" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-5">
                <h2 class="fontcss" style="color: #000; display: flex">
                  ข้อมูลทั่วไป
                </h2>
              </v-col>
              <v-col v-if="this.facebookPic != null" cols="12" class="pb-0">
                <img :src="this.facebookPic" style="width: 70px" />
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  autofocus
                  v-model="staffname"
                  :rules="[(v) => !!v || 'กรุณากรอกชื่อพนักงานขาย']"
                  required
                  ref="staffname"
                  label="กรุณากรอกชื่อพนักงานขาย"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  autofocus
                  v-model="username"
                  :rules="[(v) => !!v || 'กรุณากรอกชื่อร้านค้า']"
                  required
                  ref="refusername"
                  label="ชื่อร้านค้า"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="phone"
                  :rules="[(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์']"
                  required
                  ref="refphone"
                  label="เบอร์โทร"
                  outlined
                  @keypress="isNumberWNoDot($event)"
                  maxlength="10"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-2">
                <h2 class="fontcss" style="color: #000; display: flex">
                  ข้อมูลการเข้าสู่ระบบ
                </h2>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="email"
                  :rules="[
                    (v) => !!v || 'กรุณากรอกอีเมล',
                    (v) => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง',
                  ]"
                  required
                  ref="refemail"
                  label="อีเมล"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="password"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  :rules="[
                    (v) => !!v || 'กรุณากรอกรหัสผ่าน',
                    (v) =>
                      /[A-Z]/.test(v) ||
                      'ต้องมีตัวอักษรภาษาอังกฤษตัวใหญ่อย่างน้อย1ตัว',
                  ]"
                  required
                  ref="refPassword"
                  label="รหัสผ่าน"
                  outlined
                  @click:append="show_password = !show_password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="
                    show_confirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :type="show_confirmPassword ? 'text' : 'password'"
                  :rules="[
                    (v) => !!v || 'กรุณากรอกยืนยันรหัสผ่าน',
                    (v) =>
                      v === this.password ||
                      'รหัสผ่านไม่ตรงกัน กรุณาใส่ใหม่อีกครั้ง',
                  ]"
                  required
                  ref="refconfirmPassword"
                  label="ยืนยันรหัสผ่าน"
                  outlined
                  @click:append="show_confirmPassword = !show_confirmPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-5">
                <v-btn
                  :loading="loading"
                  class="btn-login"
                  @click="RegisterOMS()"
                >
                  <span class="fontcss">ยืนยัน</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <label class="body-1">มีบัญชีร้านค้ากับเราแล้ว ?</label>
                <a
                  class="subtitle-1"
                  style="color: rgb(118, 208, 200)"
                  @click="navigate()"
                  >ล็อคอินเข้าระบบ</a
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <br /><br /><br /><br /><br /><br /><br /><br />
          <v-row>
            <v-col class="pt-5" cols="12" align="center">
              <h1 class="fontcss text-center" style="color: #424242">
                สมัครสมาชิก
              </h1>
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <img src="@/assets/illustration_send_email.png" width="120px" />
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <label class="body-1"
                >ระบบได้ทำการส่งอีเมลไปยัง
                <label style="color: rgb(118, 208, 200)">{{
                  email
                }}</label></label
              >
              <br />
              <label class="body-1"
                >เพื่อยืนยันการเป็นสมาชิกเรียบร้อย
                กรุณาคลิกลิ้งค์ในอีเมลเพื่อดำเนินการในขั้นตอนถัดไป</label
              >
            </v-col>
          </v-row>
          <v-divider :thickness="6" class="border-opacity-100"></v-divider
          ><br />
          <label class="body-1">มีบัญชีร้านค้ากับเราแล้ว ?</label>
          <a
            class="subtitle-1"
            style="color: rgb(118, 208, 200)"
            @click="navigate()"
            >ล็อคอินเข้าระบบ</a
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<style scoped>
.btn-login {
  font-size: 2em !important;
  width: 50% !important;
  height: 60px !important;
  color: #ffffff !important;
  background: #23baaa !important;
}
.text-center {
  text-align: center; /* ใช้ text-align: center เพื่อจัดข้อความในตำแหน่งกลาง */
}
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");

.fontcss {
  font-family: "Prompt", sans-serif;
}
.layout {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}
</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import { token_api } from "@/website/token";
import { isNumberWNoDot } from "@/website/global_function";
import { shopService_dotnet } from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
export default {
  data: () => ({
    valid: true,
    loading: false,
    username: "",
    staffname: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    show_password: false,
    show_confirmPassword: false,
    nextstep: false,

    facebookPic: null,
    facebookId: null,


    // size จอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    // ขนาดจอ
    Window: "pc",
  }),
  async created() {
    this.getInfoFacebook()
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    size() {
      this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async getInfoFacebook(){
      var profile = await JSON.parse(localStorage.getItem('facebookInfo'))
      this.facebookId = profile.id
      this.staffname = profile.name
      this.username = ""
      this.email = profile.email
      this.facebookPic = profile.picture.data.url
    },
    async RegisterOMS() {
      this.nextstep = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let response = await axios.post(
          shopService_dotnet + "Register/register-by-facebook-send-email",
          {
            facebookUserId: this.facebookId,
            sellerName: this.staffname,
            phone: this.phone,
            email: this.email,
            name: this.username,
            password: this.password,
            username: this.email,
            imageUrl: this.facebookPic,
          },
          {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }}
        );
        if (response.data.status === "success") {
          this.loading = false;
          this.nextstep = true;
          Swal.fire({
            toast: true,
            timer: 3000,
            position: "top",
            type: "success",
            title: response.data.message.th, //hardcode ภาษาไทยก่อน
            showConfirmButton: false,
          });
        } else {
          this.loading = false;
          Swal.fire({
            toast: true,
            timer: 3000,
            position: "top",
            type: "error",
            title: response.data.message.th, //hardcode ภาษาไทยก่อน
            showConfirmButton: false,
          });
        }
      } else {
        this.loading = false;
        this.AlertWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    },
    navigate() {
      this.$router.push("/loginOasys");
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    isNumberWNoDot,
    AlertSuccess,
    AlertWarning,
    AlertError,
  },
};
</script>
<style scoped>
 
@media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
    .resizeBg{
      height: 100vh !important;
    }
     
  }
  @media screen and (min-device-width: 1500px)   {
    .resizeBg{
      height: 180vh !important;
    }
     
  }

</style>
