<template>
  <v-app>

  </v-app>
</template>
<script>
  import axios from 'axios'
  import { ecomService_dotnet } from '@/website/global'
  import { header_token } from '@/website/backend/token'
  export default {
    components: {
    },
    data: () => ({
      shop_id: null,
      url_callback: null
    }),
    async created () {
      this.shop_id = localStorage.getItem('shop_id')
      this.url_callback = window.location.href
      if(this.shop_id != null){
        await this.update_data_call_back()
      }else{
        var url_encode = encodeURIComponent(this.url_callback)
        this.$router.push('/login?ref='+ url_encode)
      }
    },
    watch: {
    },
    methods: {
      async update_data_call_back() {
        this.url_callback = this.url_callback.split("?")
        var parameter = this.url_callback[1]
        let response = await axios.get(ecomService_dotnet+'Line/callback?'+parameter , { headers: header_token})
        if (response.status == 200){
          this.$router.push("/connect")
        }
      }
    },
  }
</script>
