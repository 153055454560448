<template>
  <div class="mt-0" align="center">

  </div>
</template>
<script>
import axios from 'axios'
import { ecomService_dotnet } from '@/website/global'
import { DateNow, FormatInvoice, formatMoney } from '@/website/global_function'
import { header_token } from '@/website/backend/token'
import Swal from 'sweetalert2'
  export default {
    components: {
      // 'barcode': VueBarcode,
      // VueQrcode
    },
    data: () => ({

      shop_id: null,
      url_callback: null
    }),
    async created () {

      this.shop_id = localStorage.getItem('shop_id')
      this.url_callback = window.location.href

      this.jd_code = this.$route.query.code

      if(this.shop_id != null){
        await this.update_data_call_back()
      }else {
        // encode fullphat
        var url_encode = encodeURIComponent(this.url_callback)
        this.$router.push('/login?ref='+ url_encode)
      }
    },
    watch: {
    },
    methods: {
      // DateNow,
      async update_data_call_back() {

        try {
          this.loading = true
          let response = await axios.post(ecomService_dotnet+'JD/callback',{
            "code": this.jd_code,
            "shopID": this.shop_id,
            "isInit": false
          } , { headers: header_token})

          this.loading = false
          this.$router.push('/connect')
        } catch (e) {
          this.$swal({
            type: 'warning',
            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
          })
          this.$router.push('/connect')
        }


      },

    },
  }
</script>
