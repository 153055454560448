<template>
    <div class="mt-0" align="center">
  
    </div>
  </template>
  <script>
  import axios from 'axios'
  import { shopService_dotnet } from '@/website/global'
  import Swal from 'sweetalert2';
  import { token_api } from '@/website/backend/token'
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
    export default {
      components: {
      },
      data: () => ({
      }),
      async created () {
        let params = new URL(document.location).searchParams
        let verifycallback = params.get("ref")

        if(verifycallback != null){
          await this.verifyEmailcallback(verifycallback)
        }else {
            Swal.fire({
            toast: true,
            timer: 3000,
            position: 'top',
            type: 'error',
            title: "ไม่พบverify code",
            showConfirmButton: false,
            })
            this.$router.push('/loginOasys')
        }
      },
      methods: {
        async verifyEmailcallback(verifycallback) {
          let response1 = await axios.post(shopService_dotnet+'Register/register-by-email',{
                verifyCode:verifycallback
          } , {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api })
          if(response1.data.status === "success")
          {
            this.$swal({
                type: 'success',
                title: 'ลงทะเบียนเรียบร้อย',
                text: 'กรุณากดปุ่มยืนยันเพื่อเข้าสู่ระบบ',
                confirmButtonText: 'ยืนยัน',
                confirmButtonColor: '#3085d6',
                allowOutsideClick: false
                }).then(async (result) => {
                if (result.value) {
                    this.$router.push('/loginOasys')
                }
                })
          }
          else{
            Swal.fire({
                toast: true,
                timer: 3000,
                position: 'top',
                type: 'warning',
                title: response1.data.message.th,  //hardcode เป็นภาษาไทยก่อน
                showConfirmButton: false,

            })
          this.$router.push('/loginOasys')
          }

        },
    AlertSuccess,
    AlertWarning,
    AlertError,
      },
    }
  </script>
  