<template>
  <v-container fluid fill-height class=" bg-login-ss">
    <!-- for pc -->
    <div class="hidden-xs-only ml-n16">
      <v-row class="form-login mr-16 pr-16 ml-n16">
          <v-col cols="12" md="6" lg="5" align="center" >
              <v-card color="#29a4dd" class="rounded-xl">
                  <v-card-text >
                      <v-row>
                          <v-col cols="12" align="center">
                              <img src="@/assets/SS BG-02.png" width="100%">
                          </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                          lazy-validation
                      >
                        <v-stepper
                          alt-labels
                          v-model="step"
                          class="rounded-xl"
                        >
                          <v-stepper-items >
                            <v-stepper-content step="3">
                              <v-row>
                                <v-col cols="12"  class="pb-0" >
                                    <v-text-field
                                        autofocus
                                        v-model="username"
                                        label="Username"
                                        outlined
                                        :prepend-inner-icon="'mdi-account'"
                                        :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                        required
                                        @keyup.enter="$refs.password.focus()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pb-0"  >
                                  <v-text-field
                                      ref="password"
                                      v-model="password"
                                      label="Password"
                                      outlined
                                      :prepend-inner-icon="'mdi-lock'"
                                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show_password ? 'text' : 'password'"
                                      :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                      required
                                      @click:append="show_password = !show_password"
                                      @keyup.enter="bt_login_click()"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12"  class="pb-0">
                                    <v-btn
                                    class="btn-login rounded-lg"
                                    :bt_loading="bt_loading"
                                    :disabled="!valid || bt_loading"
                                    @click="bt_login_click()">เข้าสู่ระบบ</v-btn>
                                </v-col>
                                <v-col cols="12"  class="pb-0">
                                  <h2>or</h2>
                                </v-col>
                                <v-col cols="12"  class="pb-0">
                                  <v-btn class="rounded-lg" width="100%" height="50px" color="#4267b2">
                                    <fb-signin-button
                                      :params="fbSignInParams"
                                      scope="public_profile,email"
                                      @success="onSignInSuccess"
                                      @error="onSignInError">
                                        Sign in with Facebook
                                    </fb-signin-button>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                      </v-form>
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>
    </div>
    <!-- for pc -->

    <!-- for mobile -->
    <v-row justify="center" class="hidden-sm-and-up">
        <v-col cols="12" md="6" lg="4" align="center" >
            <v-card color="#29a4dd" class="rounded-xl">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" align="center">
                            <img src="@/assets/SS BG-02.png" width="100%">
                        </v-col>
                    </v-row>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                      <v-stepper
                        alt-labels
                        v-model="step"
                        class="rounded-xl"
                      >
                        <v-stepper-items >
                          <v-stepper-content step="3">
                            <v-row>
                              <v-col cols="12"  class="pb-0" >
                                  <v-text-field
                                      autofocus
                                      v-model="username"
                                      label="Username"
                                      outlined
                                      :prepend-inner-icon="'mdi-account'"
                                      :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                      required
                                      @keyup.enter="$refs.password.focus()"
                                  ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0"  >
                                <v-text-field
                                    ref="password"
                                    v-model="password"
                                    label="Password"
                                    outlined
                                    :prepend-inner-icon="'mdi-lock'"
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_password ? 'text' : 'password'"
                                    :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                    required
                                    @click:append="show_password = !show_password"
                                    @keyup.enter="bt_login_click()"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12"  class="pb-0">
                                  <v-btn
                                  class="btn-login rounded-lg"
                                  :bt_loading="bt_loading"
                                  :disabled="!valid || bt_loading"
                                  @click="bt_login_click()">เข้าสู่ระบบ</v-btn>
                              </v-col>
                              <v-col cols="12"  class="pb-0">
                                <h2>or</h2>
                              </v-col>
                              <v-col cols="12"  class="pb-0">
                                <v-btn class="rounded-lg" width="100%" height="50px" color="#4267b2">
                                  <fb-signin-button
                                    :params="fbSignInParams"
                                    scope="public_profile,email"
                                    @success="onSignInSuccess"
                                    @error="onSignInError">
                                      Sign in with Facebook
                                  </fb-signin-button>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <!-- for mobile -->
  </v-container>
</template>

<style scoped>
 >>>.v-input__prepend-inner{
   margin-top: 10px;
 }
</style>

<script>
import axios from 'axios'
import { shopService_dotnet, agentService_dotnet, generalService_dotnet } from '@/website/global'
import { token_api } from '@/website/backend/token'
import facebookLogin from 'facebook-login-vuejs';
import FBSignInButton from 'vue-facebook-signin-button' 
 export default {
  //  components: {
  //     facebookLogin
  //   },
       data: () => ({
         step: 3,
         fbSignInParams: {
            scope: 'email,public_profile',
            return_scopes: true
          },
          bt_loading: false,
          valid: true,
          username: '',
          password: '',
          show_password: false,
          userFace: '',
          passFace: '',
          status: '',

          idImage:'',
          loginImage:'',
          mailImage:'',
          faceImage:'',
          isConnected: false,
          picFace:'',
          nameFace: '',
          emailFace: '',
          personalID: '',
          FB: undefined,
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
          // sslogin
          sslogin: 1,
       }),

       async created () {
         await axios.post(generalService_dotnet+'General/get-postcodemapping', {},
            { headers: this.header_token})
            .then(response =>
                {
                localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data))
                this.postcode_mapping = response.data
                })
         if(localStorage.getItem('staff_id') == null || localStorage.getItem('staff_id') === 'undefined'){
           localStorage.setItem('navigete', JSON.stringify([
               {
                   "title": "Dashboard",
                   "path": "/dashboard"
               },
               {
                   "title": "หน้าแรก",
                   "path": "/dashboard"
               }
           ]))

           this.token_by_branch = null
           if(this.$route.query.token === 'undefined'){
             this.token_by_branch = null
           } else if ( this.$route.query.token == 'null') {
             this.token_by_branch = null
           } else if ( this.$route.query.token == null) {
             this.token_by_branch = null
           } else if (this.$route.query.token == '') {
             this.token_by_branch = null
           } else {
             this.token_by_branch = this.$route.query.token
             await this.get_user_and_password()
           }
           this.url_ref = this.$route.query.ref
         } else {
           this.token_by_branch = null
           if(this.$route.query.token === 'undefined'){
             this.token_by_branch = null
           } else if ( this.$route.query.token == 'null') {
             this.token_by_branch = null
           } else if ( this.$route.query.token == null) {
             this.token_by_branch = null
           } else if (this.$route.query.token == '') {
             this.token_by_branch = null
           } else {
             this.token_by_branch = this.$route.query.token
             await this.get_user_and_password()
           }

           if(this.token_by_branch==null){
             this.$router.push('/dashboard')
           }
         }
         window.fbAsyncInit = function() {
            this.FB.init({
              appId      : '2978410389108595',
              cookie     : true,  // enable cookies to allow the server to access the session
              xfbml      : true,  // parse social plugins on this page
              version    : 'v11.0' // use graph api version 2.8
            });
         };
         (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
       },

       methods: {
           async bt_login_click() {
               if (this.$refs.form.validate()) {
                   this.snackbar = true
                   this.bt_loading = true
                   let response = await axios.post(shopService_dotnet+'StaffAuthentication/shop-authen-shop-login', {
                     'username': this.username,
                     'password': this.password
                   },
                   { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api }})

                       var shop_id = response.data.shop_id
                       var staff_id = response.data.staff_id
                       var branch_id = response.data.branch_id
                       var is_master_user = response.data.is_master_user
                       var status = response.data.status

                       var data_permission = response.data.staffPermissionResponse ;
                       var dict_tmp_edit = {}
                       for (var i = 0; i < data_permission.length; i++) {
                         dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
                       }
                       if (status == true) {
                           localStorage.setItem('shop_id', shop_id)
                           localStorage.setItem('staff_id', staff_id)
                           localStorage.setItem("branch_id", branch_id)
                           localStorage.setItem("is_master_user", is_master_user)
                           // localStorage.setItem('authorization_token', response.data.token)
                           localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
                           // localStorage.setItem('permission', JSON.stringify(response.data.permission))
                           localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
                           if (typeof(this.url_ref) ==='undefined') {
                             this.$router.push('/dashboard')
                             localStorage.setItem('login_value', JSON.stringify(this.sslogin));
                           } else {
                             var url_decode = decodeURIComponent(this.url_ref)
                             this.$router.push(url_decode)
                           }
                       }
                       else {
                           this.$swal({
                               position: 'top',
                               type: 'info',
                               text: 'ไม่พบสมาชิก',
                           })
                           this.bt_loading = false
                       }

                   this.bt_loading = false
               }
           },

           async get_user_and_password () {

             let response2 = await axios.post(shopService_dotnet+'StaffAuthentication/shop-authen-get-user-and-password-by-token', {
               'token': this.token_by_branch
             },
             { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api }})

               var shop_id = response2.data.shop_id
               var staff_id = response2.data.staff_id
               var branch_id = response2.data.branch_id
               var is_master_user = response2.data.is_master_user
               var status = response2.data.status

               var data_permission = response2.data.staffPermissionResponse ;
               var dict_tmp_edit = {}
               for (var i = 0; i < data_permission.length; i++) {
                 dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
               }
               if (status == true) {
                   localStorage.setItem('shop_id', shop_id)
                   localStorage.setItem('staff_id', staff_id)
                   localStorage.setItem("branch_id", branch_id)
                   localStorage.setItem("is_master_user", is_master_user)
                   // localStorage.setItem('authorization_token', response2.data.token)
                   localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
                   // localStorage.setItem('permission', JSON.stringify(response2.data.permission))
                   localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
                   if (typeof(this.url_ref) ==='undefined') {
                     this.$router.push('/dashboard')
                   } else {
                     var url_decode = decodeURIComponent(this.url_ref)
                     this.$router.push(url_decode)
                   }
               }
               else {
                   this.$swal({
                       position: 'top',
                       type: 'info',
                       text: 'ไม่พบสมาชิก',
                   })
                   this.bt_loading = false
               }
           },

            async onSignInSuccess (response) {
              // console.log(response.authResponse.userID)
              window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
                async userInformation => {
                    if(userInformation.id){
                        this.personalID = userInformation.id;
                        if ( userInformation.id ) {
                          let response = await axios.post(
                              // "https://localhost:5001/AgentRegister/get-facebook-login",
                              agentService_dotnet + 'AgentRegister/get-facebook-login',
                              {
                                  'faceId': userInformation.id,
                              },
                              { headers: this.header_token }
                            )
                            this.userFace = response.data.user
                            this.passFace = response.data.pass
                            this.status = response.data.status
                            if (userInformation.id != null) {
                              let response = await axios.post(shopService_dotnet+'StaffAuthentication/shop-authen-shop-login', {
                                'username': this.userFace,
                                'password': this.passFace
                              },
                              { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api }})

                                  var shop_id = response.data.shop_id
                                  var staff_id = response.data.staff_id
                                  var branch_id = response.data.branch_id
                                  var is_master_user = response.data.is_master_user
                                  var status = response.data.status

                                  var data_permission = response.data.staffPermissionResponse ;
                                  var dict_tmp_edit = {}
                                  for (var i = 0; i < data_permission.length; i++) {
                                    dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
                                  }
                                  if (status == true) {
                                      localStorage.setItem('shop_id', shop_id)
                                      localStorage.setItem('staff_id', staff_id)
                                      localStorage.setItem("branch_id", branch_id)
                                      localStorage.setItem("is_master_user", is_master_user)
                                      localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
                                      localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
                                      if (typeof(this.url_ref) ==='undefined') {
                                        await this.$router.push('/dashboard')
                                        await window.location.reload();
                                        await localStorage.setItem('login_value', JSON.stringify(this.sslogin));
                                      } else {
                                        var url_decode = decodeURIComponent(this.url_ref)
                                        this.$router.push(url_decode)
                                      }
                                  }
                                  else {
                                      this.$swal({
                                          position: 'top',
                                          type: 'info',
                                          text: 'ไม่พบสมาชิก',
                                      })
                                      this.bt_loading = false
                                  }

                              this.bt_loading = false
                          }
                        }
                    } else {
                        console.log("User cancelled login or did not fully authorize.")
                    }
                }
              )
            },
            onSignInError (error) {
              console.log('OH NOES', error)
            }
       }
   }
</script>
<style>
   .container.fill-height > .row
   {
       max-width: none;
   }
   .button-facebook{
      color:white;
      min-width: 150px;
      background-color: #000000a1;
      height: 2.5rem;
      border-radius: 2rem;
      font-weight: 400;
      font-size: 0.8rem;
    }
    .fb-signin-button {
      /* This is where you control how the button looks. Be creative! */
      display: inline-block;
      padding: 17px;
      width: 100%;
      border-radius: 3px;
      background-color: #4267b2;
      color: #fff;
    }
</style>
