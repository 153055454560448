<template>
  <!-- Google tag (gtag.js) -->

  <v-row justify="center" >
  <v-col v-if="Window == 'pc'" class="bg-loginOasys  resizeBg fill-height" > 
    <!-- ไปแก้css login ตรงที่ทำ -->
  </v-col>
  <v-col cols="12" md="6" lg="4" align="center" class="layout">
  <v-card height="100%" width="100%"> 
    <v-card-text>
      <br/><br/><br/>
      <v-row>
        <v-col  cols="12" md="2"></v-col>
        <v-col class="py-4" cols="12" align="center">
          <img src="@/assets/logo-500x100.png" width="340px" />
          <h3 class="fontcss " style="color: #424242;" > เข้าสู่ระบบ</h3>
        </v-col>
      </v-row>
      <v-form ref="form" class="pt-5" v-model="valid" lazy-validation>
        <v-row justify="center">
          <v-col cols="10"  class="pb-0" >
                        <v-text-field
                            v-model="username"
                            label="Email"
                            outlined
                            :prepend-inner-icon="'mdi-account'"
                            :rules="[ v => !!v || 'กรุณาระบุ', ]"
                            required
                            @keyup.enter="$refs.password.focus()"
                        ></v-text-field>
          </v-col>
          <v-col cols="10" class="pb-0"  >
                      <v-text-field
                          ref="password"
                          v-model="password"
                          label="Password"
                          outlined
                          :prepend-inner-icon="'mdi-lock'"
                          :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show_password ? 'text' : 'password'"
                          :rules="[ v => !!v || 'กรุณาระบุ', ]"
                          required
                          @click:append="show_password = !show_password"
                          @keyup.enter="bt_login_click()"
                      >
                      
                    </v-text-field>
                        <a class="text-hover-underline" @click="nevigateforgot()" style="
                            display: flex;
                            flex-direction: row-reverse;
                            color:#424242 ;
                        "> ลืมรหัสผ่าน</a>
                        
          <span style="color: red;" v-if="errorMessage != ''">{{ errorMessage }} </span>
          </v-col>
        </v-row>
        <v-col class="pb-2" cols="10">
              <v-btn
              class="btn-login rounded-lg fontcss"
              :loading="bt_loading"
              :disabled="!valid || bt_loading"
              @click="bt_login_click()">เข้าสู่ระบบ</v-btn>
          </v-col>

          <v-col class="pb-2" cols="10">
          <div class="text-center my-3">
            <div class="divider-container">
              <v-divider class="flex-grow-1"></v-divider>
              <span class="mx-2 fontcss" style="padding: 0 10px;font-size: 15px; color: black;">หรือ</span>
              <v-divider class="flex-grow-1"></v-divider>
            </div>
          </div>
          </v-col>
        <v-col class="pb-2" cols="10">
              <v-btn
              class="btn-register rounded-lg fontcss"
              @click="nevigate()">สมัครสมาชิก</v-btn>
          </v-col>
        <!-- <br><v-divider  :thickness="4" class="border-opacity-100"/> -->

        <!-- facebook button -->
        <v-col class="pb-2 pt-6" cols="10">
          <v-btn block class="rounded-lg fontcss px-0">
            <fb-signin-button
              :params="fbSignInParams"
              @success="onSignInSuccess"
              @error="onSignInError"
            >
                Sign in with Facebook
            </fb-signin-button>
          </v-btn>
        </v-col>

      </v-form>
    </v-card-text>
  </v-card>
</v-col>
  </v-row>
  
</template>
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11480171580">
</script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11480171580');
</script>
<style scoped>
>>>.v-input__prepend-inner{
margin-top: 10px;
}
.btn-login{
/* font-weight: bolder !important;  */
font-size: 1.5em !important;
width:100% !important;
height:60px !important;
color: #ffffff !important;
background: #23baaa !important;
}
.btn-register{
font-weight: bolder !important;
font-size: 1.5em !important;
width:100% !important;
height:60px !important;
color: #23baaa !important;
background: #fff !important;
border: 1px solid #23baaa;
border-radius: 25px
}
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
.fontcss {
font-family: 'Prompt', sans-serif;
}
.divider-container {
display: flex;
align-items: center;
justify-content: center;
}
.v-divider {
margin-top: 0px;
}
.text-hover-underline {
}
.text-hover-underline:hover {
text-decoration: underline;
}
.layout {
padding-left: 0px !important;
padding-top: 0px !important;
padding-bottom: 0px !important;
padding-right: 0px !important;
}
</style>

<script>
import axios from 'axios'
import { shopService_dotnet, agentService_dotnet, generalService_dotnet,branchService_dotnet } from '@/website/global'
import { load_languages } from '@/website/global_function'
import { token_api } from '@/website/backend/token' 

export default {
   data: () => ({
      bt_loading: false,
      valid: true,
      username: '',
      password: '',
      show_password: false,
      loading: false,

      errorMessage : '', 
      logo:null,
      background: null,

       // size จอ
      size: "lg",
      window: {
          width: 0,
          height: 0,
      },
      // ขนาดจอ
      Window: "pc",

      //facbook
      fbSignInParams: {
        scope: 'email,public_profile',
        return_scopes: true
      },
   }),

   async created () {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
     var url = document.URL;
     const urlParams = new URLSearchParams(window.location.search);
      const branchid1 = urlParams.get('branchid');

     var doman = (url.split("/"))[2]
     var subdomain = doman.split(".")
     let branchid= 0;
     subdomain = subdomain[0]
     if (subdomain == 'fastpac' || subdomain == 'dev-fastpac'){
       branchid=83
     }else  if (url.includes( "halofulfillment")){
       branchid=301
     }else if(branchid1!=null){
      branchid=branchid1
     }

     try {
      await axios.post(branchService_dotnet+'Branch/get-branch-info?BranchID='+branchid, {},{headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }})
          .then(response =>
          {
            this.background = response.data.photo_bg_shop_login
            this.logo = response.data.logoLogin
          })
    } catch (error) {
      //do nothing
    }

     if(this.background==null){
      this.background = "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/shop/bgloginOasys.png"
     }
     if(this.logo==null){
      this.logo = "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/packhai-logo-color.png"
     }


     if(localStorage.getItem('staff_id') == null || localStorage.getItem('staff_id') === 'undefined'){
       localStorage.setItem('navigete', JSON.stringify([
           {
               "title": "Dashboard",
               "path": "/dashboard"
           },
           {
               "title": "หน้าแรก",
               "path": "/dashboard"
           }
       ]))

       this.token_by_branch = null
       if(this.$route.query.token === 'undefined'){
         this.token_by_branch = null
       } else if ( this.$route.query.token == 'null') {
         this.token_by_branch = null
       } else if ( this.$route.query.token == null) {
         this.token_by_branch = null
       } else if (this.$route.query.token == '') {
         this.token_by_branch = null
       } else {
         this.token_by_branch = this.$route.query.token
         await this.login_by_token()
       }
       this.url_ref = this.$route.query.ref
     } else {
       this.token_by_branch = null
       if(this.$route.query.token === 'undefined'){
         this.token_by_branch = null
       } else if ( this.$route.query.token == 'null') {
         this.token_by_branch = null
       } else if ( this.$route.query.token == null) {
         this.token_by_branch = null
       } else if (this.$route.query.token == '') {
         this.token_by_branch = null
       } else {
         this.token_by_branch = this.$route.query.token
         await this.login_by_token()
       }

       if(this.token_by_branch==null){
         this.$router.push('/dashboard')
       }
     }

     this.query_url_to_push = null
     if("url" in this.$route.query){
       this.query_url_to_push = this.$route.query.url
     }

    //facebook
     window.fbAsyncInit = function() {
      this.FB.init({
        appId      : '849982039770741',
        cookie     : true,  // enable cookies to allow the server to access the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v17.0' // use graph api version 2.8
      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

   },
   watch: {
      size() {
          this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
      },
      },
      destroyed() {
      window.removeEventListener("resize", this.handleResize);
      },
   methods: {
    load_languages,
       async bt_login_click() {
        this.errorMessage = '';
        if (this.$refs.form.validate()) {
            this.snackbar = true
            this.bt_loading = true
            let response = await axios.post(shopService_dotnet+'StaffAuthentication/staff-shop-login', {
              'username': this.username,
              'password': this.password
            },{headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }})
                var shop_id = response.data.shop_id
                var staff_id = response.data.staff_id
                var branch_id = response.data.branch_id
                var is_master_user = response.data.is_master_user
                var status = response.data.status
                var data_permission = response.data.staffPermissionResponse ;
                var dict_tmp_edit = {}
                for (var i = 0; i < data_permission.length; i++) {
                  dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
                }
                if (status == true) {
                    localStorage.setItem('shop_id', shop_id)
                    localStorage.setItem('staff_id', staff_id)
                    localStorage.setItem("branch_id", branch_id)
                    localStorage.setItem("is_master_user", is_master_user) 
                    localStorage.setItem('authorization_token', response.data.token)  
                    localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
                    localStorage.setItem('shop',  JSON.stringify(response.data.shop))
                    // set code to localStorage
                    for (var data_code of response.data.shopPermission_codes_new) {
                      localStorage.setItem(data_code.menuCode, data_code.authorize)
                    }
                    await  this.load_languages();
                    if(this.query_url_to_push == null){
                      if (typeof(this.url_ref) ==='undefined') {
                        this.$router.push('/dashboard')
                      } else {
                        var url_decode = decodeURIComponent(this.url_ref)
                        this.$router.push(url_decode)
                      }
                    } else {
                      this.$router.push('/'+this.query_url_to_push)
                    }
                } 
                else if(response.data.is_not_found){
                this.errorMessage = "ไม่พบผู้ใช้นี้";
                  
                }
              else if(response.data.is_wrong_password){
                this.errorMessage = "รหัสผ่านผิดกรุณาลองอีกครั้ง";
                
              }
              else if(response.data.is_lock ==  true){
                this.$swal({ 
                  type: 'error',
                  text: 'ผู้ใช้นี้ถูกล็อกกรุณาเปลี่ยนรหัสผ่านใหม่',
                })
                this.bt_loading = false
              }
              else {
                    this.$swal({
                        position: 'top',
                        type: 'info',
                        text: 'ไม่พบสมาชิก',
                    })
                    
                }
              this.bt_loading = false
        }
      },
      async get_languages_file(){
        this.set_language = localStorage.getItem("set_language");

        let response = await axios.post(generalService_dotnet +"General/get-language-file",{}, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }});

        if (this.set_language != (null && undefined)) {
          
          this.use_language =response.data.Language[this.set_language]
          localStorage.setItem("language_data",    JSON.stringify(this.use_language));
        } else {
          this.use_language = response.data.Language["TH"];
          localStorage.setItem("set_language", "TH");
        }
  

      },
      async login_by_token () {
        let response2 = await axios.post(shopService_dotnet+'StaffAuthentication/staff-shop-login', {
               'token': this.token_by_branch
             },
             { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api }})
       
          
           var shop_id = response2.data.shop_id
           var staff_id = response2.data.staff_id
           var branch_id = response2.data.branch_id
           var is_master_user = response2.data.is_master_user
           var status = response2.data.status

           var data_permission = response2.data.staffPermissionResponse ;
           var dict_tmp_edit = {}
           for (var i = 0; i < data_permission.length; i++) {
             dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
           }
           if (status == true) {
               localStorage.setItem('shop_id', shop_id)
               localStorage.setItem('staff_id', staff_id)
               localStorage.setItem("branch_id", branch_id)
               localStorage.setItem("is_master_user", is_master_user) 
               localStorage.setItem('shop',  JSON.stringify(response.data.shop)) 
               localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46') 
               localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
               localStorage.setItem('permission_new', JSON.stringify())

               // set code to localStorage
               for (var data_code of response2.data.shopPermission_codes_new) {
                 localStorage.setItem(data_code.menuCode, data_code.authorize)
               }
                 this.load_languages();
               if(this.query_url_to_push == null){
                 if (typeof(this.url_ref) ==='undefined') {
                   this.$router.push('/dashboard')
                 } else {
                   var url_decode = decodeURIComponent(this.url_ref)
                   this.$router.push(url_decode)
                 }
               }else {
                 this.$router.push('/'+this.query_url_to_push)
               }

           }
           else {
               this.$swal({
                   position: 'top',
                   type: 'info',
                   text: 'ไม่พบสมาชิก',
               })
               this.bt_loading = false
           }
       },
       nevigate(){
          this.$router.push("/auth/register");
       },
       nevigateforgot(){
          this.$router.push("/auth/forgot-password");
       },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    async onSignInSuccess (res) {
      window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
      async userInformation => {
        if(userInformation.id){
          if ( userInformation.id ) {
            let response = await axios.post(
              shopService_dotnet+'StaffAuthentication/shop-authen-shop-facebook-login',
              {
                'facebookUserID': userInformation.id
              },
              {headers: token_api}
            )
            var shop_id = response.data.shop_id
            var staff_id = response.data.staff_id
            var branch_id = response.data.branch_id
            var is_master_user = response.data.is_master_user
            var status = response.data.status
            var data_permission = response.data.staffPermissionResponse ;
            var dict_tmp_edit = {}
            for (var i = 0; i < data_permission.length; i++) {
              dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
            }
            if (status == true) {
              localStorage.setItem('shop_id', shop_id)
              localStorage.setItem('staff_id', staff_id)
              localStorage.setItem("branch_id", branch_id)
              localStorage.setItem("is_master_user", is_master_user)
              localStorage.setItem('shop',  JSON.stringify(response.data.shop)) 
              localStorage.setItem('shop_feature',  JSON.stringify(response.data.shopFeatureList))
              localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
              // localStorage.setItem('permission', JSON.stringify(response.data.permission))
              localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
              // set code to localStorage
              for (var data_code of response.data.shopPermission_codes_new) {
                localStorage.setItem(data_code.menuCode, data_code.authorize)
              }
              await  this.load_languages();
              if(this.query_url_to_push == null){
                if (typeof(this.url_ref) ==='undefined') {
                  this.$router.push('/dashboard')
                } else {
                  var url_decode = decodeURIComponent(this.url_ref)
                  this.$router.push(url_decode)
                }
              } else {
                this.$router.push('/'+this.query_url_to_push)
              }
            } else {
              localStorage.setItem('facebookInfo', JSON.stringify(userInformation))
              this.$router.push("/auth/register");
            }
          } else {
            console.log("User cancelled login or did not fully authorize.")
          }
        }
      })
    },
    onSignInError (error) {
      console.log('OH NOES', error)
    },
   }
}

</script>
<style>
.container.fill-height > .row
{
   max-width: none;
}
.button-facebook{
  color:white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
.fb-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 17px;
  width: 100%;
  border-radius: 3px;
  background-color: #4267b2;
  color: #fff;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
    .resizeBg{
      height: 100vh !important;
    }
     
  }
  @media screen and (min-device-width: 1500px)   {
    .resizeBg{
      height: 180vh !important;
    }
     
  }
</style>
