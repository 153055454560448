<template>
  <div class="mt-0" align="center">
    <v-card :elevation="0" width="400">

      <table v-if="PrintText=='PrintBasics'" style="padding: 1rem;">
        <tr height="90">
          <td width="60%" valign="top">
            <barcode :value="PrintOrderID" height="30"/>
          </td>
          <td width="40%" align="center" valign="top" class="pa-2">
            <p v-if="PrintCODAmount>0" style="margin: 0px; padding: 5px; background: #0B5345; color: white; font-size: 20px;">COD ({{PrintCODAmount}})</p>
            <p align="right" style="margin-top: 5px;">BEST Express</p>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-left: 0.25rem;" Valign="top">
            <p style="margin: 0px; font-size: 12px; padding-top: 0px;">{{use_language.sender}}</p>
            <p style="margin: 0px; font-size: 12px;">{{use_language.sender}}: {{PrintShopName}}</p>
            <p style="margin: 0px; font-size: 12px;">{{use_language.tel}}: {{PrintShopPhone}}</p>
            <p style="margin: 0px; font-size: 12px;">{{use_language.address}}: {{PrintShopAddress}}</p>
            <p style="margin: 0px; font-size: 14px; padding-top: 1px;  font-weight: bold;">{{use_language.recipient}}</p>
            <p style="margin: 0px; font-size: 14px;  font-weight: bold;">{{use_language.name}}: {{PrintCusName}}</p>
            <p style="margin: 0px; font-size: 14px;  font-weight: bold;">{{use_language.tel}}: {{PrintCusPhone}}</p>
            <p style="margin: 0px; font-size: 14px;  font-weight: bold;">{{use_language.address}}: {{PrintCusAddress}}</p>
          </td>
        </tr>
      </table>
    </v-card>
  </div>
</template>
<script>
import VueBarcode from 'vue-barcode'
// import VueQrcode from 'vue-qrcode'
// import { DateNow } from '@/website/globalFunction'
import { order_language } from "@/website/language/order_language";  
  
  export default {
    components: {
      'barcode': VueBarcode,
      // VueQrcode
    },
    data: () => ({
       use_language: null,
        set_language: null,
      //print
    
      PrintText: '',
      PrintOrderID: '',
      PrintCODAmount: '',
      PrintShopName: '',
      PrintShopPhone: '',
      PrintShopAddress: '',
      PrintCusName: '',
      PrintCusPhone: '',
      PrintCusAddress: ''
    }),
    created () {
      this.PrintText = localStorage.getItem('PrintText')
      this.PrintOrderID = localStorage.getItem('PrintOrderID')
      this.PrintCODAmount = localStorage.getItem('PrintCODAmount')
      this.PrintShopName = localStorage.getItem('PrintShopName')
      this.PrintShopPhone = localStorage.getItem('PrintShopPhone')
      this.PrintShopAddress = localStorage.getItem('PrintShopAddress')
      this.PrintCusName = localStorage.getItem('PrintCusName')
      this.PrintCusPhone = localStorage.getItem('PrintCusPhone')
      this.PrintCusAddress = localStorage.getItem('PrintCusAddress')
      print();

    },
    watch: {
    },
    methods: {
      // DateNow,
      DateNow () {
        var today = new Date();
        var date = today.getFullYear()+'-'+((today.getMonth()+1).toString().length> 1 ? (today.getMonth()+1).toString() : '0'+(today.getMonth()+1).toString())+'-'+(today.getDate().toString().length> 1 ? today.getDate().toString() : '0'+today.getDate().toString());
        var h = today.getHours().toString().length> 1 ? today.getHours().toString() : '0'+today.getHours().toString()
        var m = today.getMinutes().toString().length> 1 ? today.getMinutes().toString() : '0'+today.getMinutes().toString()
        var s = today.getSeconds().toString().length> 1 ? today.getSeconds().toString() : '0'+today.getSeconds().toString()
        var time = h + ':' + m + ':' + s
        var newDatetime = date+' '+time;
        return newDatetime
      }
    },
  }
</script>
