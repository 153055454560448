<template>
  <v-row>
    <v-col v-if="Window == 'pc'" class="bg-loginOasys resizeBg fill-height ">
      <!-- ไปแก้css login ตรงที่ทำ -->
    </v-col>

  


    <v-col v-if="Window == 'pc' ||  Window == 'mobile'  " cols="12" md="6" lg="4" align="center"
      class="layout">
      <v-card height="100%" width="100%">
        
        <v-card-text v-if="!registerEmailSuccess && !registerFacebookSuccess">
          <v-row>
            <v-col class="pt-5" cols="12">
              <img src="@/assets/logo-500x100.png" width="340px" />
              <h1 class="fontcss text-center" style="color: #424242;">สมัครสมาชิก</h1>
            </v-col>
            <v-col v-if="this.facebookPic != null" cols="12" class="pb-0">
              <img :src="this.facebookPic" style="width: 70px" />
            </v-col>
            <v-col v-if="this.staffName != null" cols="12" class="pb-0">
              <h2>{{ staffName }}</h2>
            </v-col>
          </v-row>
          <v-form ref="form" class="pt-5" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-5">
                <h2 class="fontcss " style="color: #000; display: flex;">ข้อมูลทั่วไป</h2>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field autofocus v-model="shopName" :rules="[(v) => !!v || 'กรุณากรอกชื่อร้านค้า']" required
                  ref="refusername" label="ชื่อร้านค้า" outlined></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="phone" :rules="[(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์']" required ref="refphone"
                  label="เบอร์โทร" outlined @keypress="isNumberWNoDot($event)" maxlength="10"></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="referenceCode" ref="refphone" label="รหัสผู้แนะนำ" outlined></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-2">
                <h2 class="fontcss " style="color: #000; display: flex;">ข้อมูลการเข้าสู่ระบบ</h2>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="email"
                  :rules="[(v) => !!v || 'กรุณากรอกอีเมล', v => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง']" required
                  ref="refemail" label="อีเมล" outlined></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="password" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่าน', v => /[A-Z]/.test(v) || 'ต้องมีตัวอักษรภาษาอังกฤษตัวใหญ่อย่างน้อย1ตัว']"
                  required ref="refPassword" label="รหัสผ่าน" outlined
                  @click:append="show_password = !show_password"></v-text-field>

              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="confirmPassword" :append-icon="show_confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_confirmPassword ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'กรุณากรอกยืนยันรหัสผ่าน', v => v === this.password || 'รหัสผ่านไม่ตรงกัน กรุณาใส่ใหม่อีกครั้ง']"
                  required ref="refconfirmPassword" label="ยืนยันรหัสผ่าน" outlined
                  @click:append="show_confirmPassword = !show_confirmPassword"></v-text-field>

              </v-col>
              <v-col cols="12" class="pt-5">
                <v-btn :loading=loading class="btn-login" @click="RegisterOMS()">
                  <span class="fontcss">ยืนยัน</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <label class="body-1">มีบัญชีร้านค้ากับเราแล้ว ?</label> <a class="subtitle-1"
                  style="color: rgb(118,208,200);" @click="navigate()">ล็อคอินเข้าระบบ</a>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-else-if="registerEmailSuccess">
          <br><br><br><br><br><br><br><br>
          <v-row>
            <v-col class="pt-5" cols="12" align="center">
              <h1 class="fontcss text-center" style="color: #424242;">สมัครสมาชิก สำเร็จ</h1>
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <h1 class="fontcss text-center" style="color: #424242;">กรุณายืนยัน Email ของคุณ</h1>
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <img src="@/assets/illustration_send_email.png" width="120px" />
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <label class="body-1">ระบบได้ทำการส่งอีเมลไปยัง <label style="color: rgb(118,208,200);">{{ email
                  }}</label></label> <br>
              <label class="body-1">เพื่อยืนยันการเป็นสมาชิกเรียบร้อย
                กรุณาคลิกลิ้งค์ในอีเมลเพื่อดำเนินการในขั้นตอนถัดไป</label>
            </v-col>

          </v-row>
          <v-divider :thickness="6" class="border-opacity-100"></v-divider><br>
          <label class="body-1">มีบัญชีร้านค้ากับเราแล้ว ?</label> <a class="subtitle-1"
            style="color: rgb(118,208,200);" @click="navigate()">ล็อคอินเข้าระบบ</a>
        </v-card-text>
        <v-card-text v-else-if="registerFacebookSuccess">
          <br><br><br><br><br><br><br><br>
          <v-row>
            <v-col class="pt-5" cols="12" align="center">
              <h1 class="fontcss text-center" style="color: #424242;">สมัครสมาชิก สำเร็จ</h1>
            </v-col>

            <v-col class="pt-5" cols="12" align="center">
              <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/correct.png" width="120px" />
            </v-col>
            <v-col class="pt-5" cols="12" align="center">
              <label class="body-1">ระบบกำลังสร้างร้านค้าให้กับคุณ </label> <br>
              <label class="body-1">กรุณารอสักครู่ ระบบจะพาคุณไปหน้าร้านค้าโดยอัตโนมัติ</label>
            </v-col>

          </v-row>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>
<style scoped>
.btn-login {
  font-size: 2em !important;
  width: 50% !important;
  height: 60px !important;
  color: #ffffff !important;
  background: #23baaa !important;
}

.text-center {
  text-align: center;
  /* ใช้ text-align: center เพื่อจัดข้อความในตำแหน่งกลาง */
}

@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

.fontcss {
  font-family: 'Prompt', sans-serif;
}

.layout {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}
</style>
<script>
import axios from "axios";
import Swal from 'sweetalert2'
import { header_token } from "@/website/token";
import { isNumberWNoDot, load_languages } from '@/website/global_function'
import { shopService_dotnet } from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
export default {
  data: () => ({
    valid: true,
    loading: false,
    shopName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    show_password: false,
    show_confirmPassword: false,
    registerEmailSuccess: false,
    registerFacebookSuccess: false,
    facebookId: null,
    staffName: null,
    facebookPic: null,
    referenceCode: null,

    // size จอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    // ขนาดจอ
    Window: "pc",
    token_chanel_register: null,
     
  }),
  async created() {
    let token = this.$route.query;
    if (Object.keys(token).length > 0) {
      localStorage.setItem('token_channel_register', token)

      var tokenFirst = window.location.href
      var taxt = tokenFirst.split("?");
      token = taxt[1];
      console.log(token);
      this.token_chanel_register = token
    } else {
      this.token_chanel_register = localStorage.getItem('token_channel_register')
      console.log(this.token_chanel_register);
    }
    if (this.$route.query.ref != null) {
      this.referenceCode = this.$route.query.ref
    }
    this.getInfoFacebook();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    size() {
      this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
      if (this.Window == "mobile") {
        this.showPopup = true;
        console.log(this.showPopup);

      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getInfoFacebook() {
      var profile = JSON.parse(localStorage.getItem('facebookInfo'))
      if (profile !== null) {
        this.facebookId = profile.id
        this.staffName = profile.name
        this.username = ""
        this.email = profile.email
        this.facebookPic = profile.picture.data.url

      }
    },
    async RegisterOMS() {
      this.registerEmailSuccess = false;
      this.registerFacebookSuccess = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let response = await axios.post(
          shopService_dotnet + "Register/register-new-shop",
          {
            shopName: this.shopName,
            phone: this.phone,
            email: this.email,
            password: this.password,
            facebookId: this.facebookId,
            staffName: this.staffName,
            facebookPic: this.facebookPic,
            referenceCode: this.referenceCode,
            referenceChannelToken: this.token_chanel_register
          },
          { headers: header_token }
        );
        if (response.data.status === "success") {
          this.loading = false;
          if (this.facebookId != null) {
            this.registerFacebookSuccess = true;

            let response = await axios.post(shopService_dotnet + 'StaffAuthentication/shop-authen-shop-login', {
              'username': this.email,
              'password': this.password
            }, { headers: header_token })
            var shop_id = response.data.shop_id
            var staff_id = response.data.staff_id
            var branch_id = response.data.branch_id
            var is_master_user = response.data.is_master_user
            var status = response.data.status
            var data_permission = response.data.staffPermissionResponse;
            var dict_tmp_edit = {}
            for (var i = 0; i < data_permission.length; i++) {
              dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
            }
            if (status == true) {
              localStorage.setItem('shop_id', shop_id)
              localStorage.setItem('staff_id', staff_id)
              localStorage.setItem("branch_id", branch_id)
              localStorage.setItem("is_master_user", is_master_user)
              // localStorage.setItem('authorization_token', response.data.token)
              localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
              // localStorage.setItem('permission', JSON.stringify(response.data.permission))
              localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
              // set code to localStorage
              for (var data_code of response.data.shopPermission_codes_new) {
                localStorage.setItem(data_code.menuCode, data_code.authorize)
              }
              await this.load_languages();
              if (this.query_url_to_push == null) {
                if (typeof (this.url_ref) === 'undefined') {
                  this.$router.push('/dashboard')
                } else {
                  var url_decode = decodeURIComponent(this.url_ref)
                  this.$router.push(url_decode)
                }
              } else {
                this.$router.push('/' + this.query_url_to_push)
              }
            } else {
              this.$swal({
                position: 'top',
                type: 'info',
                text: 'ไม่พบสมาชิก',
              })
            }

          }
          else {

            this.registerEmailSuccess = true;
            Swal.fire({
              toast: true,
              timer: 3000,
              type: 'success',
              title: response.data.message.th,  //hardcode ภาษาไทยก่อน
              showConfirmButton: false,
            })
          }
        } else {
          this.loading = false;
          Swal.fire({
            toast: true,
            timer: 3000,
            position: 'top',
            type: 'error',
            title: response.data.message.th, //hardcode ภาษาไทยก่อน
            showConfirmButton: false,
          })
        }

      }
      else {
        this.loading = false;
        this.AlertWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    },
    navigate() {
      this.$router.push("/loginOasys");
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    isNumberWNoDot,
    load_languages,
    AlertSuccess,
    AlertWarning,
    AlertError,
  },
};
</script>

<style scoped>
.bottom-right-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.top-right-icon {
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
    .resizeBg{
      height: 100vh !important;
    }
     
  }
  @media screen and (min-device-width: 1500px)   {
    .resizeBg{
      height: 180vh !important;
    }
     
  }

</style>
