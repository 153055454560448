<template>
    <v-row >
        <v-col v-if="Window == 'pc'" class="bg-loginOasys resizeBg fill-height"> 
          <!-- ไปแก้css login ตรงที่ทำ -->
        </v-col>
      <v-col   cols="12" md="6" lg="4" align="center" class="layout">
        <v-card height="100%" width="100%">

          <v-card-text>
            <br><br><br><br><br><br>
            <v-row>
              <v-col class="pt-5" cols="12" md="5" align="center">
                <h1 class="fontcss" style="color: #000;">ลืมรหัสผ่าน</h1>
              </v-col>
              <v-col class="pt-5" cols="12" align="left">
                 <label class="fontcss" style="font-size: 15px; padding: 25px;">กรุณาตั้งรหัสผ่านใหม่</label>
              </v-col>
            </v-row >
            <v-form ref="formPassword" class="pt-2" v-model="valid" lazy-validation >
              <v-row justify="center">
                <v-col cols="10" class="pb-0" >
                  <v-text-field
                  v-model="password"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่าน',  v => /[A-Z]/.test(v) || 'ต้องมีตัวอักษรภาษาอังกฤษตัวใหญ่อย่างน้อย1ตัว']"
                  required
                  ref="refPassword"
                  label="รหัสผ่าน"
                  outlined
                  @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>

                <v-col cols="10" class="pb-0" >
                  <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show_confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_confirmPassword ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'กรุณากรอกยืนยันรหัสผ่าน' , v => v === this.password || 'รหัสผ่านไม่ตรงกัน กรุณาใส่ใหม่อีกครั้ง']"
                  required
                  ref="refconfirmPassword"
                  label="ยืนยันรหัสผ่าน"
                  outlined
                  @click:append="show_confirmPassword = !show_confirmPassword"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-2" cols="10">
                    <v-btn
                    class="btn-login rounded-lg fontcss"
                    :loading = "loading"
                    @click="handleResetPasswordClick(1)">รีเซ็ตรหัสผ่าน</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  
  </template>
  <style  scoped >
  
  .btn-login{
  font-weight: bold !important; 
  font-size: 1.5em !important;
  width:80% !important;
  height:50px !important;
  color: #ffffff !important;
  background: #23baaa !important;
  }
  .text-center {
  text-align: center; /* ใช้ text-align: center เพื่อจัดข้อความในตำแหน่งกลาง */
  }
  @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
  
  .fontcss {
    font-family: 'Prompt', sans-serif;
  }
  .layout {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }
  </style>
  <script>
  import axios from "axios";
  import Swal from 'sweetalert2'
  import { token_api } from "@/website/token";
  import { isNumberWNoDot } from '@/website/global_function'
  import {  shopService_dotnet } from "@/website/global";
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
  export default {
  data: () => ({
  valid: true,
  loading: false,
  email:"",
  verifyCode:"",
  phone:"",
  password:"",
  confirmPassword:"",
  show_password: false,
  show_confirmPassword: false,
  
      // size จอ
      size: "lg",
      window: {
        width: 0,
        height: 0,
      },
      // ขนาดจอ
      Window: "pc",
  }),
  async created() {
    let params = new URL(document.location).searchParams
    let verifycallback = params.get("ref")
    if(verifycallback != null){
          await this.verifyEmailcallback(verifycallback)
    }else {
        this.verifyCode = null
        this.phone = localStorage.getItem('phone_number')
        if(this.phone === null || this.phone === "" || this.phone === undefined)
        {
            this.$router.push("/loginOasys");
        } 
    }

  window.addEventListener("resize", this.handleResize);
  this.handleResize();
  },
  watch: {
      size() {
        this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  methods: {

async handleResetPasswordClick(type) {
if (this.$refs.formPassword.validate()) {
if(type === 1)
{
  this.loading = true;
  let response = await axios.post(
    shopService_dotnet+"Shop/reset-password-by-mobileandemail",
    {
      phone: this.phone === "" ? null : this.phone,
      password:this.password,
      verifyCode:this.verifyCode
    },
    {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }}
  );
  if (response.data.status) {
    this.loading = false;
    localStorage.removeItem('phone_number')
    Swal.fire({
            toast: true,
            timer: 3000,
            position: 'top',
            type: 'success',
            title: 'เปลี่ยนรหัสผ่านเรียบร้อย',
            showConfirmButton: false,
            })
    this.$router.push("/loginOasys");
    }
    }
    }
    },
    async verifyEmailcallback(verifycallback) {
          let response1 = await axios.post(shopService_dotnet+'Shop/reset-password-by-email-verify',{
                verifyCode:verifycallback
          } ,{headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }})
          if(response1.data.status === 'success')
          {
            this.verifyCode = verifycallback
          }
          else{
            Swal.fire({
                toast: true,
                timer: 3000,
                position: 'top',
                type: 'error',
                title: response1.data.message.th, //hardcode ภาษาไทยไว้ก่อน
                showConfirmButton: false,
            })
          this.$router.push('/loginOasys')
          }

        },
  
  handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        if (this.window.width < 600) {
          this.size = "xs";
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = "sm";
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = "md";
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = "lg";
        } else if (this.window.width >= 1904) {
          this.size = "xl";
        }
      },
  isNumberWNoDot,
  AlertSuccess,
  AlertWarning,
  AlertError,
  },
  };
  </script>
  <style scoped>
 
  @media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
      .resizeBg{
        height: 100vh !important;
      }
       
    }
    @media screen and (min-device-width: 1500px)   {
      .resizeBg{
        height: 180vh !important;
      }
       
    }
  
  </style>